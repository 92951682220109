import { Page404 } from '@/components/pages/Page404'
import { graphql } from 'gatsby'

export default Page404

export const query = graphql`
    query PageNotFound {
        file(relativePath: { eq: "character.jpg" }) {
            childImageSharp {
                gatsbyImageData(width: 700)
            }
        }
    }
`
