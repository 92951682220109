import * as React from "react"

import { GlobalLayout } from "@/components/GlobalLayout"
import { PageNotFoundQuery } from "@root/types/graphql-types"
import { useImage } from "@/hooks/useImage"
import { PageProps } from "gatsby"
import { Head } from "@/components/Head"

type Props = PageProps<PageNotFoundQuery>

export const Page404: React.FC<Props> = ({ data }) => {
    const messages = [
        "ページ、ねえじゃん",
        "すんません、お探しのページないみたいで",
        "腹減ったなぁ",
        "やぁ、元気？",
        "あばばばばばばば",
        "おっかしいな〜",
    ]
    const { getImageProps, Image } = useImage()

    return (
        <GlobalLayout Head={<Head title="page is not found" noIndex />}>
            <div className="p-404">
                <div className="p-404__title-wrap">
                    <h2 className="p-404__title">404</h2>
                    <div className="p-404__sub-title">Page is Not Found</div>
                </div>
                <div className="p-404__serif c-fukidashi">
                    {messages[Math.floor(Math.random() * messages.length)]}
                    <br />
                </div>
                {data.file?.childImageSharp?.gatsbyImageData && (
                    <Image
                        {...getImageProps(
                            data.file.childImageSharp.gatsbyImageData,
                            {
                                alt: "yubinoriくん",
                                className: "p-404__character",
                            }
                        )}
                    />
                )}
            </div>
        </GlobalLayout>
    )
}
